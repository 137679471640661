<template>
  <div>
    <div>
      <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
      <span class="leading-none font-medium">Additional Info</span>
    </div>

    <div class="mt-4">
      <label class="vs-input--label">Vendor Name</label>
      <v-select
        :danger="validation.vendor_id ? true : false"
        :value="formDropDown.vendor_id"
        @input="updateForm({ key: 'vendor_id', value: $event })"
        :options="vendors"
      />
      <span
        class="text-danger text-sm"
        v-show="validation.vendor_id ? true : false"
      >
        {{ validation.vendor_id ? validation.vendor_id[0] : "" }}
      </span>
    </div>
    <div class="mt-4">
      <label class="vs-input--label">Region</label>
      <v-select
        :danger="validation.region_id ? true : false"
        :value="formDropDown.region_id"
        @input="updateForm({ key: 'region_id', value: $event })"
        :options="package_regions"
      />
      <span
        class="text-danger text-sm"
        v-show="validation.region_id ? true : false"
      >
        {{ validation.region_id ? validation.region_id[0] : "" }}
      </span>
    </div>

    <div class="mt-4">
      <label class="vs-input--label">Activity Type</label>
      <v-select
        :danger="validation.activity_id ? true : false"
        :value="formDropDown.activity_id"
        @input="updateForm({ key: 'activity_id', value: $event })"
        :options="activities"
      />
      <span
        class="text-danger text-sm"
        v-show="validation.activity_id ? true : false"
      >
        {{ validation.activity_id ? validation.activity_id[0] : "" }}
      </span>
    </div>

    <div class="mt-4">
      <label class="vs-input--label">Departure Type</label>
      <v-select
        :danger="validation.departure_type ? true : false"
        :value="formDropDown.departure_type"
        @input="updateForm({ key: 'departure_type', value: $event })"
        :options="departure_types"
      />
      <span
        class="text-danger text-sm"
        v-show="validation.departure_type ? true : false"
      >
        {{ validation.departure_type ? validation.departure_type[0] : "" }}
      </span>
    </div>

    <div class="mt-4">
      <label class="vs-input--label">Package Type</label>
      <v-select
        :danger="validation.package_type ? true : false"
        :value="formDropDown.package_type"
        @input="updateForm({ key: 'package_type', value: $event })"
        :options="package_types"
      />
      <span
        class="text-danger text-sm"
        v-show="validation.package_type ? true : false"
      >
        {{ validation.package_type ? validation.package_type[0] : "" }}
      </span>
    </div>

    <div class="mt-4">
      <label class="vs-input--label">Listing Type</label>
      <v-select
        :danger="validation.listing_type ? true : false"
        :value="formDropDown.listing_type"
        @input="updateForm({ key: 'listing_type', value: $event })"
        :options="listing_types"
      />
      <span
        class="text-danger text-sm"
        v-show="validation.listing_type ? true : false"
      >
        {{ validation.listing_type ? validation.listing_type[0] : "" }}
      </span>
    </div>

    <vs-input
      class="w-full mt-4"
      label="Price Per Person"
      :danger="validation.price_per_person ? true : false"
      :danger-text="
        validation.price_per_person ? validation.price_per_person[0] : ''
      "
      :value="form.price_per_person"
      @input="updateForm({ key: 'price_per_person', value: $event })"
    />

    <vs-input
      class="w-full mt-4"
      label="Age"
      :danger="validation.age ? true : false"
      :danger-text="validation.age ? validation.age[0] : ''"
      :value="form.age"
      @input="updateForm({ key: 'age', value: $event })"
    />

    <vs-input
      class="w-full mt-4"
      label="Total Person (person >=9)"
      :danger="validation.discount_total_no ? true : false"
      :danger-text="
        validation.discount_total_no ? validation.discount_total_no[0] : ''
      "
      :value="form.discount_total_no"
      @input="updateForm({ key: 'discount_total_no', value: $event })"
    />

    <vs-input
      class="w-full mt-4"
      label="Discount Percentage"
      :danger="validation.discount_percentage ? true : false"
      :danger-text="
        validation.discount_percentage ? validation.discount_percentage[0] : ''
      "
      :value="form.discount_percentage"
      @input="updateForm({ key: 'discount_percentage', value: $event })"
    />

    <vs-input
      class="w-full mt-4"
      label="Guide Language"
      :danger="validation.guide_language ? true : false"
      :danger-text="
        validation.guide_language ? validation.guide_language[0] : ''
      "
      :value="form.guide_language"
      @input="updateForm({ key: 'guide_language', value: $event })"
    />

    <vs-input
      class="w-full mt-4"
      label="Guiding Method"
      :danger="validation.guiding_method ? true : false"
      :danger-text="
        validation.guiding_method ? validation.guiding_method[0] : ''
      "
      :value="form.guiding_method"
      @input="updateForm({ key: 'guiding_method', value: $event })"
    />

    <vs-input
      class="w-full mt-4"
      label="Position"
      :danger="validation.position ? true : false"
      :danger-text="validation.position ? validation.position[0] : ''"
      :value="form.position"
      @input="updateForm({ key: 'position', value: $event })"
    />
  </div>
</template>

<script>
import vSelect, { vOption } from "vue-select";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      package_region_id: 29,
      roleOptions: [
        { label: "Admin", value: "admin" },
        { label: "User", value: "user" },
        { label: "Staff", value: "staff" },
      ],
    };
  },
  components: {
    vSelect,
    vOption,
  },

  mounted() {
    this.fetchAndSetVendors();
    this.fetchPackageRegions();
    this.fetchVendors();
    this.fetchActivities();
    this.fetchDepartureTypes();
    this.fetchPackageTypes();
    this.fetchListingTypes();
  },
  computed: {
    ...mapGetters({
      package_regions: "package_region/getPackageRegionsDropdown",
      form: "main_package/getFormObj",
      formDropDown: "main_package/getFormDropdownObj",
      validation: "validation/getValidationErrors",
      vendors: "vendor/fetchVendorsDropdown",
      activities: "activity/getActivitiesDropdown",
      departure_types: "departure_type/fetchDepartureTypesDropdown",
      package_types: "package_type/fetchPackageTypesDropdown",
      listing_types: "listing_type/fetchListingTypesDropdown",
    }),
  },
  methods: {
    ...mapActions({
      fetchPackageRegions:
        "package_region/fetchAndSetAllPackageRegionsDropdown",
      fetchAndSetVendors: "main_package/fetchAndSetVendors",
      updateForm: "main_package/updateForm",
      fetchVendors: "vendor/fetchAndSetVendorsDropdown",
      fetchActivities: "activity/fetchAndSetActivitiesDropDown",
      fetchDepartureTypes: "departure_type/fetchAndSetDepartureTypesDropdown",
      fetchPackageTypes: "package_type/fetchAndSetPackageTypesDropdown",
      fetchListingTypes: "listing_type/fetchAndSetListingTypesDropdown",
    }),
  },
};
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
