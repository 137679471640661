<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Package Details</span>
      </div>
      <div class="vx-col w-full">
        <vs-input
          class="w-full mt-4"
          label="Package Name"
          :value="form.name"
          @input="updateForm({ key: 'name', value: $event })"
        />
        <span
          class="text-danger text-sm"
          v-show="validation.name ? true : false"
        >
          {{ validation.name ? validation.name[0] : "" }}
        </span>
      </div>
      <div class="vx-col w-full md:w-2/3">
        <vs-input
          class="w-full mt-4"
          label="Slug"
          :danger="validation.slug ? true : false"
          :danger-text="validation.slug ? validation.slug[0] : ''"
          :value="form.slug"
          @input="updateForm({ key: 'slug', value: $event })"
        />
      </div>

      <div class="vx-col w-full md:w-1/3">
        <vs-input
          class="w-full mt-4"
          label="Package Duration"
          :danger="validation.duration ? true : false"
          :danger-text="validation.duration ? validation.duration[0] : ''"
          :value="form.duration"
          @input="updateForm({ key: 'duration', value: $event })"
        />
      </div>

      <div class="vx-col w-full md:w-1/3">
        <label class="vs-input--label">Tag</label>
        <v-select
          :danger="validation.tag ? true : false"
          :value="form_dropdown.tag"
          @input="updateForm({ key: 'tag', value: $event })"
          :options="tags"
        />
        <span
          class="text-danger text-sm"
          v-show="validation.tag ? true : false"
        >
          {{ validation.tag ? validation.tag[0] : "" }}
        </span>
      </div>

      <div class="vx-col w-full mt-4">
        <label>Available Features</label>
        <v-select
          multiple
          :danger="validation.available_features ? true : false"
          :value="geo.available_features"
          :options="available_features"
          @input="
            handleMultipleSelect({ key: 'available_features', value: $event })
          "
        />

        <span
          class="text-danger text-sm"
          v-show="validation.available_features ? true : false"
        >
          {{
            validation.available_features
              ? validation.available_features[0]
              : ""
          }}
        </span>
      </div>
    </div>

    <label>Overview</label>
    <ckeditor
      :editor="editor"
      :config="editorConfig"
      class="w-full mt-4"
      :value="form.overview"
      @input="updateForm({ key: 'overview', value: $event })"
    />

    <span
      class="text-danger text-sm"
      v-show="validation.overview ? true : false"
    >
      {{ validation.overview ? validation.overview[0] : "" }}
    </span>

    <label>Highlights</label>
    <ckeditor
      :editor="editor"
      :config="editorConfig"
      class="w-full mt-4"
      :value="form.highlights"
      @input="updateForm({ key: 'highlights', value: $event })"
    />
    <span
      class="text-danger text-sm"
      v-show="validation.highlights ? true : false"
    >
      {{ validation.highlights ? validation.highlights[0] : "" }}
    </span>

    <label>Includes</label>
    <ckeditor
      :editor="editor"
      :config="editorConfig"
      class="w-full mt-4"
      :value="form.includes"
      @input="updateForm({ key: 'includes', value: $event })"
    />

    <span
      class="text-danger text-sm"
      v-show="validation.includes ? true : false"
    >
      {{ validation.includes ? validation.includes[0] : "" }}
    </span>

    <label>Excludes</label>
    <ckeditor
      :editor="editor"
      :config="editorConfig"
      class="w-full mt-4"
      :value="form.excludes"
      @input="updateForm({ key: 'excludes', value: $event })"
    />

    <span
      class="text-danger text-sm"
      v-show="validation.excludes ? true : false"
    >
      {{ validation.excludes ? validation.excludes[0] : "" }}
    </span>

    <label>Good To Know</label>
    <ckeditor
      :editor="editor"
      :config="editorConfig"
      class="w-full mt-4"
      :value="form.good_to_know"
      @input="updateForm({ key: 'good_to_know', value: $event })"
    />

    <span
      class="text-danger text-sm"
      v-show="validation.good_to_know ? true : false"
    >
      {{ validation.good_to_know ? validation.good_to_know[0] : "" }}
    </span>

    <label>Cancellation</label>
    <ckeditor
      :editor="editor"
      :config="editorConfig"
      class="w-full mt-4"
      :value="form.cancellation"
      @input="updateForm({ key: 'cancellation', value: $event })"
    />

    <span
      class="text-danger text-sm"
      v-show="validation.cancellation ? true : false"
    >
      {{ validation.cancellation ? validation.cancellation[0] : "" }}
    </span>

    <label>Accommodation</label>
    <ckeditor
      :editor="editor"
      :config="editorConfig"
      class="w-full mt-4"
      :value="form.accommodation"
      @input="updateForm({ key: 'accommodation', value: $event })"
    />

    <span
      class="text-danger text-sm"
      v-show="validation.accommodation ? true : false"
    >
      {{ validation.accommodation ? validation.accommodation[0] : "" }}
    </span>
  </div>
</template>

<script>
import vSelect, { vOption } from "vue-select";
import { mapGetters, mapActions } from "vuex";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  data() {
    return {
      editor: ClassicEditor,
      editorData: "<p>Content of the editor.</p>",
      editorConfig: {
        // toolbar: [ 'bold', 'italic', '|', 'link' ]
      },
    };
  },
  mounted() {
    this.fetchFeatures();
  },
  components: {
    vSelect,
    vOption,
  },
  computed: {
    ...mapGetters({
      form: "main_package/getFormObj",
      geo: "main_package/getGeo",
      validation: "validation/getValidationErrors",
      available_features: "feature_dropdown/fetchFeaturesDropdown",
      form_dropdown: "main_package/getFormDropdownObj",
      tags: "main_package/getTags",
    }),
  },
  methods: {
    ...mapActions({
      updateForm: "main_package/updateForm",
      fetchFeatures: "feature_dropdown/fetchAndSetFeaturesDropdown",
    }),
    handleMultipleSelect($event) {
      this.updateForm($event);
    },
  },
};
</script>
