<template>
  <div>
    <div class="">
      <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
      <span class="leading-none font-medium">Location</span>
    </div>
    <vs-input 
      class="w-full mt-4" 
      label="Max Altitude" 
      :danger="validation.max_altitude?true:false"
      :danger-text="validation.max_altitude?validation.max_altitude[0]:''"
      :value="form.max_altitude"
      @input="updateForm({key: 'max_altitude', value: $event})" />

    <vs-input 
      class="w-full mt-4" 
      label="Location Start From" 
      :danger="validation.location_start_from?true:false"
      :danger-text="validation.location_start_from?validation.location_start_from[0]:''"
      :value="form.location_start_from"
      @input="updateForm({key: 'location_start_from', value: $event})" />

    <vs-input 
      class="w-full mt-4" 
      label="Location End To" 
      :danger="validation.location_end_to?true:false"
      :danger-text="validation.location_end_to?validation.location_end_to[0]:''"
      :value="form.location_end_to"
      @input="updateForm({key: 'location_end_to', value: $event})" />

    <vs-input 
      class="w-full mt-4" 
      label="Google Map Code" 
      :danger="validation.google_map?true:false"
      :danger-text="validation.google_map?validation.google_map[0]:''"
      :value="form.google_map"
      @input="updateForm({key: 'google_map', value: $event})" />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    vSelect
  },
  computed: {
    ...mapGetters({
        form: 'main_package/getFormObj',
        validation: 'validation/getValidationErrors'
    })
  },
  methods: {
    ...mapActions({
      updateForm: 'main_package/updateForm'
    })
  }
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
